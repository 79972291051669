import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import useTranslation from "sholdi-hooks/useTranslation";

import Sholdi from "sholdi-icons/Sholdi";
import Link from "sholdi-primitives/atoms/Link";
import Text from "sholdi-primitives/atoms/Text";
import Button from "sholdi-primitives/atoms/Button";

const inheritedProps = "";

const SholdiClubBanner = ({ className, ...props }) => {
  const { t } = useTranslation();
  return (
    <div
      className={twMerge(
        "flex w-full p-8 md:p-16 bg-primary-lightest rounded-lg",
        className,
      )}
      {...props}
    >
      <Sholdi />
      <div className="flex w-full flex-col lg:flex-row">
        <div className="ml-5">
          <div className="flex flex-wrap leading-9 text-3xl md:text-3xl lg:text-3xl">
            <Text
              variant="h3"
              className={twMerge(
                "text-3xl md:text-3xl lg:text-3xl whitespace-nowrap mr-2 font-semibold",
                inheritedProps,
              )}
            >
              {t("shop.becomeMember")}
            </Text>
            <Text
              variant="h3"
              className={twMerge(
                "text-3xl md:text-3xl lg:text-3xl mr-2 text-primary-main font-semibold",
                inheritedProps,
              )}
            >
              Sholdi
            </Text>
            <Text
              variant="h3"
              className={twMerge(
                "text-3xl md:text-3xl lg:text-3xl w-min font-semibold",
                inheritedProps,
              )}
              {...inheritedProps}
            >
              {t("club")}
            </Text>
          </div>
          <Text className="hidden lg:block text-black text-xl md:text-xl lg:text-xl leading-8">
            {t("shop.sholdiClubDescription")}
          </Text>
        </div>
        <Button
          as={Link}
          href="/about-sholdi"
          className="h-12 ml-5 xl:ml-auto mt-8 xl:mt-0 w-full lg:w-fit hover:no-underline"
        >
          <Text className="text-center text-white">{t("learnMore")}</Text>
        </Button>
      </div>
    </div>
  );
};

SholdiClubBanner.displayName = "SholdiClubBanner";

SholdiClubBanner.propTypes = {
  className: PropTypes.string,
};

export default SholdiClubBanner;
